<template>
  <base-modal
    :is-visible="isVisible"
    class="tariff-popup"
    @closeModal="closeModal"
  >
    <template>
      <div class="tariff-header">Повышаем цены на 25%</div>
      <div class="tariff-body">
        Сообщаем, что с 3 февраля 2025 года цены тарифов повышаются на 25%. Это
        связано с выходом нового функционала и доработками существующего.
      </div>
      <div class="tariff-body">
        Уже сейчас добавлена "Юнит-экономика (план)". В ближайшем будущем мы
        представим новые инструменты: "Юнит-экономика (акция)", Доработки отчёта
        РнП и многое другое.
      </div>
      <div class="tariff-body tariff-body--purple">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.500001 8C0.500001 3.85786 3.85787 0.499999 8 0.499999C12.1421 0.5 15.5 3.85786 15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.500001 8ZM7.16667 11.3333C7.16667 10.8731 7.53976 10.5 8 10.5C8.46024 10.5 8.83333 10.8731 8.83333 11.3333C8.83333 11.7936 8.46024 12.1667 8 12.1667C7.53976 12.1667 7.16667 11.7936 7.16667 11.3333ZM7.16667 3.83333L7.16667 8.83333L8.83333 8.83333L8.83333 3.83333L7.16667 3.83333Z"
            fill="#481173"
          />
        </svg>
        <div v-if="!hasSubscription || hasTrialSubscription">
          Для тех, кто оформит подписку до 3 февраля 2025 года, цена останется
          прежней еще на 3 месяца.
        </div>
        <div v-else-if="hasSubscription">
          Для вас цена останется прежней еще на 3 месяца.
        </div>
      </div>
      <button
        v-if="!hasSubscription || hasTrialSubscription"
        class="apply-btn"
        type="submit"
        @click="getSubscription"
      >
        Оформить подписку
      </button>

      <button
        v-else-if="hasSubscription"
        class="apply-btn"
        type="submit"
        @click="done"
      >
        Понятно
      </button>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/views/reports/report-6/components/modals/BaseModal.vue';
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default {
  name: 'TariffUpPopup',
  components: { BaseModal },
  data() {
    return {
      isOpened: true,
    };
  },
  methods: {
    ...mapActions(['getProfileInfo']),
    done() {
      localStorage.setItem('hasSeenTariffUp', '1');
      this.isOpened = false;
    },
    getSubscription() {
      this.done();
      this.$router.push({ name: 'tariffs' });
    },
    hasSeen() {
      return Boolean(localStorage.getItem('hasSeenTariffUp'));
    },
    closeModal(outside) {
      if (outside !== true) {
        this.done();
      }
      this.isOpened = false;
    },
  },
  watch: {
    '$route.path': {
      handler() {
        const today = dayjs();
        const february3 = dayjs().set('month', 1).set('date', 3).startOf('day');
        this.isOpened = false;
        if (!today.isBefore(february3)) {
          return;
        }
        if (!this.isAuthenticated) {
          return;
        }
        this.getProfileInfo();
        if (!this.hasSeen()) {
          this.isOpened = true;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters(['currentUserTariffInfo']),
    hasSubscription() {
      return this.currentUserTariffInfo.has_active_subscription;
    },
    hasTrialSubscription() {
      return (
        this.currentUserTariffInfo.current_subscription?.tariff?.name ===
        'Trial'
      );
    },
    isVisible() {
      if (!this.isOpened) {
        return false;
      }
      if (!this.isAuthenticated) {
        return false;
      }
      return !this.hasSeen();
    },
  },
};
</script>

<style scoped>
.tariff-popup {
    width: 420px;
    height: 480px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
}

.tariff-header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
}

.tariff-body {
    margin-bottom: 15px;
}

.tariff-body--purple {
    background-color: #FCF8FF;
    color: #481173;
    padding: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;

    & svg {
        width: 50px;
        height: 30px;
    }
}

.apply-btn {
  background-color: #541CAB;
  color: white;
  border: none;
  border-radius: 10px;
  width: 100%;
    height: 70px;
}
</style>
