<template>
  <base-modal
    :is-visible="isVisible"
    @closeModal="closeModal"
    class="import-modal"
    id="import-modal-id"
  >
    <p class="title">Импорт файла</p>
    <div class="body">
      <p class="body-title">Выберите колонки</p>
      <div class="columns">
        <div class="column">
          <p>Себестоимость</p>
        </div>
      </div>
      <div v-if="file" class="filename">
        <p>Файл: {{ file.name }}</p>
      </div>
    </div>
    <div class="footer">
      <b-btn
        variant="default"
        class="download-btn"
        style="margin-right: auto"
        @click="downloadTemplate"
      >
        Скачать шаблон</b-btn
      >
      <b-btn class="wb-btn" @click="browse">
        <span class="ml-2">Выбрать файл</span>
      </b-btn>
      <b-btn v-if="file" class="wb-btn" @click="importFile">
        <UploadIcon class="import-icon" />
        <span class="ml-2">Импортировать</span>
      </b-btn>
      <input
        ref="fileInput"
        type="file"
        class="file-input"
        accept=".xlsx"
        @input="fileUpload"
      />
    </div>
  </base-modal>
</template>

<script>
import BaseModal from '@/views/reports/report-6/components/modals/BaseModal.vue';
import UploadIcon from '@/views/reports/report-6/components/icons/UploadIcon.vue';
import { utils, read, write } from 'xlsx';
import CloseIcon from '@/views/reports/report-6/components/icons/CloseIcon.vue';
import { mapActions, mapGetters } from 'vuex';
import { currencyFormatter } from '@/helpers/numberFormatter';

export default {
  name: 'ImportModal',
  components: { CloseIcon, UploadIcon, BaseModal },
  props: ['isVisible'],
  emits: ['closeModal'],
  data() {
    return {
      file: null,
      payload: null,
    };
  },
  computed: {
    ...mapGetters('report6', ['products']),
  },
  methods: {
    ...mapActions('report6', ['updateProducts', 'getData']),
    closeModal() {
      this.payload = null;
      this.file = null;
      this.$emit('closeModal');
    },
    async fileUpload(event) {
      this.file = event.target.files[0];
      const rows = await this.extractXlsxRows();
      const payload = this.preparePayload(rows);
      if (!payload || payload.length === 0) {
        this.$notify({
          title: 'Ни один артикул не был найден. Загрузите подходящий файл.',
          type: 'error',
          duration: 10000,
        });
        this.file = null;
        return;
      }
      this.payload = payload;
    },
    browse() {
      this.$refs.fileInput.click();
    },
    extractXlsxRows() {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const workbook = read(e.target?.result, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const rows = utils.sheet_to_json(sheet, {
            header: 1,
            raw: false,
            defval: '',
          });
          const notEmptyRows = rows.filter((row) =>
            Object.values(row).some((value) => value !== '')
          );
          resolve(notEmptyRows);
        };
        reader.readAsArrayBuffer(this.file);
      });
    },
    preparePayload(rows) {
      const productIds = {};
      this.products.forEach((el) => {
        productIds[el.nm_id] = el.id;
      });
      return rows
        .filter((el) => {
          return el[0] !== '' && this.getNumber(el[2]) !== null;
        })
        .filter((el) => Object.keys(productIds).includes(el[0]))
        .map((el) => {
          return {
            id: productIds[Number(el[0])],
            field: 'cost_price_manual',
            value: this.getNumber(el[2]),
          };
        });
    },
    getNumber(value) {
      if (!value) {
        return null;
      }
      const cleanedString = value.replace(/[$,₽;RUB'"\s]/g, '');
      if (value === '') {
        return null;
      }
      if (!isNaN(Number(cleanedString))) {
        return Number(cleanedString);
      }
      return null;
    },
    importFile() {
      if (this.payload === null) {
        return;
      }
      if (this.payload.length === 0) {
        return;
      }
      this.updateProducts({ products: this.payload });
      this.$notify({
        title: 'Импорт завершен',
        type: 'success',
        duration: 5000,
      });
      this.getData();
      this.closeModal();
    },
    downloadTemplate() {
      const data = [['Артикул WB', 'Артикул продавца', 'Себестоимость']];
      this.products.forEach((product) => {
        data.push([
          product.nm_id,
          product.sa_name,
          currencyFormatter(
            {
              value: product.cost_price_manual || product.cost_price,
            },
            ''
          ),
        ]);
      });
      const worksheet = utils.aoa_to_sheet(data);
      worksheet['!cols'] = [{ wch: 20 }, { wch: 30 }, { wch: 30 }];
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const file = write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([file], { type: 'application/octet-stream' });
      const modal = document.getElementById('import-modal-id');
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'Шаблон.xlsx';
      modal.appendChild(link);
      link.click();
      modal.removeChild(link);
    },
  },
};
</script>

<style lang="scss">
.import-modal {
    width: 550px;
    height: 250px;
    position: absolute;
    top: 350%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;

  & .close-btn svg {
    height: 15px !important;
  }

  & .title {
    font-size: 18px;
    font-weight: 700;
  }

  & .filename {
    margin: 10px 0 15px 0;
    display: flex;
    gap: 5px;
    align-items: center;

     & .delete-icon {
       width: 25px;
       height: 25px;
       cursor: pointer;
     }
  }

  & .footer {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  & .body {
    margin-top: 20px;
    &-title {
      color: #9EA6B8;
    }
  }

  & .column {
    margin-top: 5px;
    background-color: #EEE8F7;
    padding: 5px;
    border-radius: 15px;
    width: 140px;
    text-align: center;
    color: #541CAB;
  }

  .file-input {
    display: none;
  }

  .download-btn {
  color: #541CAB;
  background-color: #EEE8F7;
  border: none;
  border-radius: 15px;

    &:hover {
        color: #541CAB;
  background-color: #EEE8F7;
    }
  }
}
</style>
