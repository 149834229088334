<script setup>
const list = [
  'Детализация',
  'Продажи',
  'Заказы',
  'Рекламная статистика',
  'Детали по товару',
  'Детали по РК',
  'РК с истекающим лимитом',
  'ДРР',
];
</script>

<template>
  <div class="landing_statistic_pluses">
    <div class="landing_statistic_pluses__left">
      <img
        class="landing_statistic_pluses__left__img"
        src="/img/landing-plus-img.svg"
        alt=""
      />
    </div>

    <div class="landing_statistic_pluses__right">
      <h6 class="landing_statistic_pluses__title">
        <span class="landing_word_color">WBLead</span> позволяет получать
        расширенную <span class="landing_word_color">статистику</span> в
        реальном времени:
      </h6>

      <ul class="landing_statistic_pluses__list">
        <li v-for="item in list" :key="item">
          <div class="landing_statistic_pluses__list_container">
            <img src="/img/landing-plus-icon.svg" alt="" />
            <span class="landing_statistic_pluses__plus">
              {{ item }}
            </span>
          </div>
        </li>
      </ul>

      <p class="landing_statistic_pluses__description">
        Благодаря интеграции с официальным API Wildberries, наш сервис получает
        статистику в реальном времени. Мы собираем данные с важнейших отчётов и
        отображаем их в одном месте. Вам больше не нужно собирать отчёты из
        десятков excel документов. Мы сделали это для вас!
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.landing_statistic_pluses {
  display: flex;
  @media screen and (min-width: 570px) {
    align-items: center;
    justify-content: center;
    &__list {
      display: grid;
      grid-template-columns: auto auto;
    }
    &__right {
      width: 480px;
    }
  }
  @media screen and (max-width: 570px) {
    flex-direction: column-reverse;
    &__left__img {
      width: 100%;
    }
    &__title {
      max-width: 100%;
      font-size: 28px;
      margin-top: 40px;
      display: block;
      white-space: wrap;
    }
  }

  &__list {
    margin: 32px 0;
    padding: 0;
    list-style: none;

    & li {
      margin-bottom: 9px;
    }
  }

  &__description {
    font-size: 18px;
  }
}

.landing_statistic_pluses__list_container {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
