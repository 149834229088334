import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import LoginView from '@/views/auth/LoginView.vue';
import ForgetView from '@/views/auth/ForgetView.vue';
import RegisterView from '@/views/auth/RegisterView.vue';
import OtpView from '@/views/auth/OtpView.vue';
import ResetView from '@/views/auth/ResetView.vue';
import Organizations from '@/views/organizations';
import Dashboard from '@/views/dashboard';
import Reports from '@/views/reports';
import ProductsAdvertising from '@/views/reports/productsAdvertising';
import ProductsStatistic from '@/views/reports/productsStatistic';
import CompaniesAdvertising from '@/views/reports/companiesAdvertising';
import StatisticAdvertising from '@/views/reports/statisticAdvertising';
import ViewInstruction from '@/views/ViewInstruction.vue';
import ViewLanding from '@/views/auth/ViewLanding.vue';
import AgreementView from '@/views/AgreementView.vue';
import Confidential from '@/views/Confidential.vue';
import ViewProfile from '@/views/ViewProfile.vue';
import ViewRegularPaymentOffer from '@/views/ViewRegularPaymentOffer.vue';
import ViewTariffs from '@/views/ViewTariffs.vue';
import ViewUnsubscribeTariff from '@/views/ViewUnsubscribeTariff.vue';
import Report5 from '@/views/reports/report-5/index.vue';
import Report6 from '@/views/reports/report-6/index.vue';
import InstructionView from '@/views/InstructionView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Dashboard,
  },
  {
    path: '/tariffs',
    name: 'tariffs',
    component: ViewTariffs,
  },
  {
    path: '/unsubscribe',
    name: 'unsubscribe',
    component: ViewUnsubscribeTariff,
    meta: {
      layout: 'LayoutTariffs',
    },
  },
  {
    path: '/payment-offer',
    name: 'paymentOffer',
    component: ViewRegularPaymentOffer,
    meta: {
      layout: 'LayoutBeforeReg',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: ViewProfile,
  },
  {
    path: '/landing',
    name: 'auth.landing',
    component: ViewLanding,
  },
  {
    path: '/auth/login',
    name: 'auth.login',
    component: LoginView,
    meta: {
      layout: 'AuthLayout',
    },
  },
  {
    path: '/auth/forget',
    name: 'auth.forget',
    component: ForgetView,
    meta: {
      layout: 'AuthLayout',
    },
  },
  {
    path: '/auth/reset',
    name: 'auth.reset',
    component: ResetView,
    meta: {
      layout: 'AuthLayout',
    },
  },
  {
    path: '/auth/register',
    name: 'auth.register',
    component: RegisterView,
    meta: {
      layout: 'AuthLayout',
    },
  },
  {
    path: '/auth/otp',
    name: 'auth.otp',
    component: OtpView,
    meta: {
      layout: 'AuthLayout',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/instruction',
    name: 'instruction',
    component: InstructionView,
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: AgreementView,
    meta: {
      layout: 'LayoutBeforeReg',
    },
  },
  {
    path: '/confidential',
    name: 'confidential',
    component: Confidential,
    meta: {
      layout: 'LayoutBeforeReg',
    },
  },
  {
    path: '/organizations',
    name: 'organizations',
    component: Organizations,
  },
  {
    path: '/reports',
    name: 'reports',
    component: Reports,
    redirect: '/reports/products-advertising',
    children: [
      {
        path: '/reports/products-advertising',
        name: 'productsAdvertising',
        component: ProductsAdvertising,
      },
      {
        path: '/reports/products-statistic/:id',
        name: 'productsStatistic',
        component: ProductsStatistic,
      },
      {
        path: '/reports/products-statistic',
        name: 'productsStatistic',
        component: ProductsStatistic,
      },
      {
        path: '/reports/companies-advertising',
        name: 'CompaniesAdvertising',
        component: CompaniesAdvertising,
      },
      {
        path: '/reports/statistic-advertising/:id',
        name: 'StatisticAdvertising',
        component: StatisticAdvertising,
      },
      {
        path: '/instruction',
        name: 'Instruction',
        component: ViewInstruction,
      },
      {
        path: '/reports/dyn-products',
        name: 'Report5',
        component: Report5,
      },
      {
        path: '/reports/unit-economics',
        name: 'Report6',
        component: Report6,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === 'auth.login') {
    if (
      store.getters['auth/accessToken'] &&
      store.getters['auth/refreshToken']
    ) {
      next({ name: 'home' });
    } else {
      next();
    }
  } else {
    if (
      (store.getters['auth/accessToken'] &&
        store.getters['auth/refreshToken']) ||
      (to.name && to.name.includes('auth'))
    ) {
      next();
    } else {
      if (
        to.name === 'agreement' ||
        to.name === 'confidential' ||
        to.name === 'paymentOffer' ||
        to.name === 'tariffs' ||
        to.name === 'instruction'
      ) {
        next();
        return;
      }

      next({ name: 'auth.landing' });
    }
  }
});

export default router;
