<template>
  <div
    v-if="isVisible"
    @click.stop
    v-click-outside-element="outsideClick"
    class="change-modal"
  >
    <slot></slot>
    <div class="close-btn" @click.prevent="closeModal">
      <svg viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.77295 6.97699C7.87862 7.08266 7.93799 7.22599 7.93799 7.37543C7.93799 7.52487 7.87862 7.66819 7.77295 7.77387C7.66728 7.87954 7.52395 7.9389 7.37451 7.93891C7.22507 7.93891 7.08175 7.87954 6.97607 7.77387L3.99998 4.79684L1.02295 7.77293C0.917276 7.8786 0.773954 7.93797 0.624511 7.93797C0.475068 7.93797 0.331746 7.8786 0.226073 7.77293C0.120401 7.66726 0.0610352 7.52393 0.0610352 7.37449C0.0610352 7.22505 0.120401 7.08173 0.226073 6.97605L3.2031 3.99996L0.227011 1.02293C0.121339 0.917256 0.0619727 0.773934 0.0619727 0.624491C0.0619727 0.475048 0.121339 0.331726 0.227011 0.226054C0.332683 0.120382 0.476005 0.0610155 0.625448 0.0610155C0.774891 0.0610155 0.918214 0.120382 1.02389 0.226054L3.99998 3.20309L6.97701 0.225585C7.08268 0.119913 7.22601 0.0605469 7.37545 0.0605469C7.52489 0.0605469 7.66821 0.119913 7.77389 0.225585C7.87956 0.331257 7.93892 0.474579 7.93892 0.624022C7.93892 0.773466 7.87956 0.916788 7.77389 1.02246L4.79685 3.99996L7.77295 6.97699Z"
          fill="#191F2E"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal',
  props: ['isVisible'],
  emits: ['closeModal'],
  data() {
    return {
      ignoreOutsideClick: true,
    };
  },
  watch: {
    isVisible() {
      this.ignoreOutsideClick = true;
    },
  },
  methods: {
    closeModal(outside = false) {
      this.$emit('closeModal', outside);
    },
    outsideClick() {
      if (this.ignoreOutsideClick) {
        this.ignoreOutsideClick = false;
        return;
      }
      this.closeModal(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.change-modal {
  background-color: white;
  position: absolute;
  z-index: 10;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}
.close-btn {
  background-color: white;
  z-index: 1000;
  width: 40px;
  height: 40px;
  position: absolute;
  right: -15px;
  top: -15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  cursor: pointer;

  & svg {
    height: 30px;
    width: 30px;
    color: black;
  }
}
</style>
