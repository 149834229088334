import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import auth from './modules/auth';
import productStatistic from './modules/productsStatistic';
import companiesAdvertising from '@/store/modules/companiesAdvertising';
import productsAdvertising from '@/store/modules/productsAdvertising';
import staticAdvertising from '@/store/modules/statisticAdvertisment';
import reports from '@/store/modules/reports';
import users from '@/store/modules/users';
import organizations from './modules/organizations';
import report5 from './modules/report-5';
import report6 from './modules/report-6';

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,

  modules: {
    auth,
    productStatistic,
    productsAdvertising,
    companiesAdvertising,
    reports,
    users,
    staticAdvertising,
    organizations,
    report5,
    report6,
  },
});
