import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from 'vee-validate';
import ru from 'vee-validate/dist/locale/ru.json';
import * as rules from 'vee-validate/dist/rules';
import Notifications from 'vue-notification';
import VueApexCharts from 'vue-apexcharts';
import VueYoutube from 'vue-youtube';

import vueClickOutsideElement from 'vue-click-outside-element';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Utils from '@/mixins/Utils.vue';
import './plugins/axios';

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'swiper/css/swiper.css';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueAwesomeSwiper);
Vue.use(Notifications);
Vue.use(VueApexCharts);
Vue.use(VueYoutube);
Vue.use(vueClickOutsideElement);

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize('ru', ru);

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.mixin(Utils);

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
