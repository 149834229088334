<script setup>
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { mobileSize } from '@/constants/mobile-size';
import LandingOpportunity from '@/components/landing/LandingOpportunity.vue';

const opportunities = [
  {
    id: 1,
    src: '/img/landing-opportunites/1.svg',
    title: 'Отчёт по всем товарам в рекламе',
    text: 'Сводный отчёт по каждому товару в вашем кабинете продавца позволит контролировать показатели всех товаров в рекламе.',
  },
  {
    id: 2,
    src: '/img/landing-opportunites/2.svg',
    title: 'Отчёт по всем рекламным кампаниям',
    text: 'Сводный отчёт по всем рекламным кампаниям позволит принимать правильные решения по распределению бюджета.',
  },
  {
    id: 3,
    src: '/img/landing-opportunites/3.svg',
    title: 'Детальный отчёт по товару',
    text: 'Полный анализ статистики по товару и рекламным кампаниям в которых товар участвовал. Ключевые показатели: CPL, CPO, CPS',
  },
  {
    id: 4,
    src: '/img/landing-opportunites/4.svg',
    title: 'Детальный отчёт по кампании',
    text: 'Полный разбор всей статистики рекламной кампании. Анализ показателей за каждый день позволяет эффективнее использовать бюджет.',
  },
  {
    id: 5,
    src: '/img/landing-opportunites/5.svg',
    title: 'Дашборд',
    text: 'Дашборд на главной странице кабинета позволяет контролировать главные показатели по кампаниям в реальном времени.',
  },
];
const RefSwiper = ref(null);
const currentPageIndex = ref(1);

const isMobile = window.innerWidth < mobileSize;
const slidesAmount = isMobile ? 1 : 3;

const prev = (event) => {
  if (currentPageIndex.value * slidesAmount !== slidesAmount) {
    RefSwiper.value.$swiper.slidePrev();
    currentPageIndex.value--;
  }
};

const next = (event) => {
  if (currentPageIndex.value * slidesAmount < opportunities.length) {
    RefSwiper.value.$swiper.slideNext();
    currentPageIndex.value++;
  }
};
</script>

<template>
  <div id="Opportunities" class="landing_opportunities">
    <div class="landing_opportunities__header">
      <h5 class="landing_opportunities__title">Возможности сервиса</h5>

      <div class="landing_opportunities__header__grid">
        <p class="landing_opportunities__description">
          Сервис WBLead предоставляет инструментарий для глубокого анализа
          рекламных и финансовых отчётов.
        </p>

        <div v-if="!isMobile" class="landing_opportunities__control">
          <a
            @click="prev"
            class="landing_opportunities__control_btn .swiper-button-prev"
          >
            <img src="/img/landing-control-left.svg" alt="" />
          </a>
          <a
            @click="next"
            class="landing_opportunities__control_btn .swiper-button-next"
          >
            <img src="/img/landing-control-right.svg" alt="" />
          </a>
        </div>
      </div>
    </div>

    <div class="landing_opportunities__list">
      <swiper
        :allow-touch-move="true"
        :slides-per-view="slidesAmount"
        :autoplay="{ delay: 3500 }"
        ref="RefSwiper"
      >
        <swiper-slide
          class="slide"
          v-for="opportunity in opportunities"
          :key="opportunity.id"
        >
          <LandingOpportunity :opportunity="opportunity" />
        </swiper-slide>
      </swiper>
    </div>
    <div v-if="isMobile" class="landing_opportunities__control centered">
      <a
        @click="prev"
        class="landing_opportunities__control_btn .swiper-button-prev"
      >
        <img src="/img/landing-control-left.svg" alt="" />
      </a>
      <a
        @click="next"
        class="landing_opportunities__control_btn .swiper-button-next"
      >
        <img src="/img/landing-control-right.svg" alt="" />
      </a>
    </div>
  </div>
</template>

<style lang="scss">
.landing_opportunities {
  padding: 80px 100px;
  overflow: hidden;
  background: var(
    --landing-light-grad,
    linear-gradient(
      103deg,
      rgba(112, 13, 190, 0.1) 14.03%,
      rgba(203, 17, 171, 0.1) 100%
    )
  );

  &__header {
    margin-bottom: 40px;
  }

  &__title {
    font-size: 32px;
  }

  &__description {
    font-size: 18px;
  }

  &__list {
    & .swiper-wrapper {
      gap: 36px;
    }

    & .slide {
      width: 440px !important;
    }
  }

  &__header__grid {
    align-items: center;
    display: grid;
    grid-template-columns: auto 120px;
    gap: 1rem;
  }

  &__control {
    display: flex;
    align-items: center;
    gap: 24px;
    &.centered {
      justify-content: center;
    }
  }

  &__description {
    max-width: 600px;
    font-size: 18px;
  }
  @media screen and (max-width: 570px) {
    padding: 40px 5%;
    &__title {
      font-size: 28px;
    }
    &__description {
      margin-top: 20px;
    }
    &__header__grid {
      display: block;
    }
    &__list {
      & .swiper-wrapper {
        gap: 0px;
      }
      & .slide {
        width: 100% !important;
      }
    }
    &__control {
      justify-content: center;
      margin-top: 40px;
    }
  }
}
</style>
