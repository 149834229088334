import axios from 'axios';
import { NOT_SUITABLE_TARIFF } from '@/constants/error-responses';

export const actions = {
  setDefaultOptions({ commit }, id) {
    commit('SET_OPTIONS', {
      wb_account: id,
    });
  },

  getData({ commit, state }) {
    commit('REPORT6_SET_LOADING', true);
    commit('SET_SUITABLE_SUBSCRIPTION', true);
    axios({
      url: '/new-reports/unit_economics/',
      method: 'POST',
      data: state.options,
    })
      .then((response) => {
        commit('REPORT6_SET_STORES', response.data.stores);
        commit('REPORT6_SET_PRODUCTS', response.data.products);
      })
      .catch((err) => {
        commit(
          'SET_SUITABLE_SUBSCRIPTION',
          err.response.data.detail !== NOT_SUITABLE_TARIFF
        );
      })
      .finally(() => {
        commit('REPORT6_SET_LOADING', false);
      });
  },
  updateProducts({ commit, state }, options) {
    const data = { ...state.options, ...options };
    axios({
      url: '/new-reports/unit_economics/',
      method: 'POST',
      data: data,
    })
      .then((response) => {
        commit('REPORT6_SET_UPDATED_PRODUCTS', response.data.products);
      })
      .catch((err) => {
        console.error(err);
      });
  },
  setOptions({ commit, state }, options) {
    const data = { ...state.options, ...options };
    commit('SET_OPTIONS', data);
  },
};
