<script setup>
import LandingFooterTelegram from '@/components/landing/LandingFooterTelegram.vue';
import LandingHostInfo from '@/components/landing/LandingHostInfo.vue';
import { mobileSize } from '@/constants/mobile-size';

const isMobile = window.innerWidth < mobileSize;
</script>

<template>
  <div class="landing_footer_left">
    <a class="landing_footer_left__logo" href="/landing">
      <img src="/img/landing-logo-footer.svg" alt="" />
      <span class="landing_footer_left__logo__text">WBLead</span>
    </a>

    <p class="landing_footer_left__text">
      Есть вопросы? Нашли баг? Нужна доработка?
    </p>

    <LandingFooterTelegram />

    <LandingHostInfo v-if="!isMobile" />
  </div>
</template>

<style lang="scss" scoped>
.landing_footer_left {
  &__logo {
    margin-bottom: 20px;
    display: flex;
    gap: 14px;
    align-items: center;
    text-decoration: none;

    &__text {
      font-size: 24px;
      font-weight: 500;
    }
  }

  &__text {
    margin-bottom: 1rem;
    font-size: 18px;
  }
}
</style>
