var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table"},[_c('ag-grid-vue',{staticClass:"ag-theme-alpine custom-grid",staticStyle:{"width":"100%","height":"100%"},attrs:{"row-height":"50px","enableCellTextSelection":"true","defaultColDef":_vm.defaultColDef,"columnDefs":_vm.columns,"rowData":_vm.rows.products,"pinnedBottomRowData":_vm.getPinnedBottomRowData()},on:{"grid-ready":_vm.onGridReady,"filter-changed":_vm.onFilterChanged}}),_c('StoreModal',{class:_vm.storeModal.ModalClasses,style:({
      top: _vm.storeModal.ModalPosition.top + 'px',
      left: _vm.storeModal.ModalPosition.left + 'px',
    }),attrs:{"is-visible":_vm.storeModal.ModalVisible,"values":_vm.storeModal.ModalValues,"value":_vm.storeModal.ModalValue,"monopalet":_vm.storeModal.ModalMonopalet},on:{"closeModal":_vm.closeStoreModal,"setValue":_vm.setStore}}),_c('PriceModal',{style:({
      top: _vm.basePriceModal.ModalPosition.top + 'px',
      left: _vm.basePriceModal.ModalPosition.left + 'px',
    }),attrs:{"is-visible":_vm.basePriceModalVisible,"price":_vm.basePriceModal.ModalPrice,"manualPrice":_vm.basePriceModal.ModalManualPrice},on:{"closeModal":_vm.closeBasePriceModal,"setPrice":_vm.setBasePrice,"refresh":_vm.setBasePrice}}),(_vm.columns.length)?_c('NewModalSettings',{ref:"settingsModal",attrs:{"pageName":"report-6","columns":_vm.columns,"show-zeros":_vm.getShowZeros()},on:{"setColumns":_vm.setColumns,"setShowZeros":_vm.setShowZeros}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }