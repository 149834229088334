<template>
  <base-modal @closeModal="closeModal()" :is-visible="isVisible">
    <div class="modal-body">
      <div>
        <div class="input-container">
          <b-icon
            icon="search"
            class="input-icon input-container__search"
          ></b-icon>
          <b-form-input
            v-model="search"
            @input="searchFilterItems"
            class="input-search"
            placeholder="Поиск"
          ></b-form-input>
        </div>
        <div v-if="isObjectValue">
          <b-form-checkbox
            v-model="modelValue"
            :value="val.name"
            class="store-chk"
            v-for="val in stores"
            :key="val.name"
            :checked="isChecked(val)"
            >{{ val.name }}
          </b-form-checkbox>
        </div>
        <div v-else>
          <b-form-checkbox
            v-model="modelValue"
            :value="val"
            class="store-chk"
            v-for="val in stores"
            :key="val"
            :checked="isChecked(val)"
            >{{ val }}
          </b-form-checkbox>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="apply-btn" @click.prevent="setValue(false)">
        Применить
      </button>
      <button class="apply-all-btn" @click.prevent="setValue(true)">
        Применить ко всем
      </button>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from '@/views/reports/report-6/components/modals/BaseModal.vue';
import debounce from 'debounce';
export default {
  name: 'StoreModal',
  components: { BaseModal },
  props: ['values', 'value', 'isVisible', 'monopalet'],
  emits: ['closeModal', 'setValue'],
  data() {
    return {
      modelValue: '',
      search: '',
      stores: [],
    };
  },
  beforeMount() {
    this.modelValue = this.value;
    this.setStores();
  },
  computed: {
    isObjectValue() {
      return this.values[0]?.name;
    },
  },
  watch: {
    value() {
      this.modelValue = this.value;
    },
    values() {
      this.setStores();
    },
    isVisible() {
      this.search = '';
      this.stores = this.values;
      this.modelValue = this.value;
    },
    monopalet() {
      this.setStores();
    },
  },
  methods: {
    setStores() {
      if (this.monopalet) {
        this.stores = this.values.filter((el) => {
          if (this.monopalet === 'Нет') {
            return el.can_hold_box;
          } else if (this.monopalet === 'Да') {
            return el.can_hold_pallet;
          }
        });
      } else {
        this.stores = this.values;
      }
    },
    closeModal() {
      this.$emit('closeModal');
    },
    isChecked(str) {
      return this.modelValue === str;
    },
    setValue(all) {
      this.$emit('setValue', this.modelValue, all);
    },
    searchFilterItems: debounce(function (searchValue) {
      if (searchValue === '') {
        this.setStores();
      } else {
        this.setStores();
        this.stores = this.stores.filter((filterValue) =>
          ('' + filterValue.name).toLowerCase().includes(searchValue)
        );
      }
    }, 300),
  },
};
</script>
<style lang="scss" scoped>
.modal-header {
  margin-bottom: 10px;
  width: 100%;

  & .input-container {
    width: 100%;
    padding: 0;
  }

  & .input-search {
    width: 100%;
  }
}

.modal-body {
  padding: 10px;
  display: flex;
  width: 100%;
  overflow-y: auto;

  & div:first-child {
    width: 100%;
  }
}

.modal-footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-top: 15px;
  background-color: white;
  padding: 10px;
}

.apply-btn {
  background-color: #541CAB;
  color: white;
  border: none;
  border-radius: 10px;
  width: 100%;
}

.apply-all-btn {
  color: #541CAB;
  background-color: #EEE8F7;
  border: none;
  border-radius: 15px;
  width: 100%;
}

.modal-checkbox {
  display: flex;
}

.store-chks {
  height: 200px;
  border: 1px solid #f1f1f1;
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  overflow-y: auto;
}

.store-chk {
  height: 35px;
  cursor: pointer;
}

.input-container {
  width: 100%;
  margin-bottom: 10px;
}

.input-search {
  width: 100%;
}
</style>
