<template>
  <base-modal
    class="price-modal"
    @closeModal="closeModal()"
    :is-visible="isVisible"
  >
    <div class="modal-body">
      <input
        type="number"
        ref="priceInput"
        v-model="value"
        class="price-input"
      />
    </div>
    <form @submit.prevent="setPrice(false)" class="modal-footer">
      <button class="apply-btn" type="submit">Применить</button>
      <button class="apply-all-btn" @click.prevent="setPrice(true)">
        Применить ко всем
      </button>
      <button
        v-if="manualPrice"
        class="apply-all-btn"
        @click.prevent="refresh(false)"
      >
        Отменить изменения
      </button>
      <button class="apply-all-btn" @click.prevent="refresh(true)">
        Сбросить всю колонку
      </button>
    </form>
  </base-modal>
</template>

<script>
import BaseModal from '@/views/reports/report-6/components/modals/BaseModal.vue';

export default {
  name: 'PriceModal',
  components: { BaseModal },
  props: ['isVisible', 'price', 'manualPrice'],
  emits: ['closeModal'],
  data() {
    return {
      value: '',
    };
  },
  watch: {
    price() {
      this.setValue();
    },
    manualPrice() {
      this.setValue();
    },
  },
  methods: {
    setValue() {
      if (this.manualPrice) {
        this.value = this.manualPrice;
      } else {
        this.value = this.price;
      }
      this.$nextTick(() => {
        this.$refs.priceInput?.focus();
      });
    },
    closeModal() {
      this.$emit('closeModal');
    },
    setPrice(all) {
      this.$emit('setPrice', this.value, all);
    },
    refresh(all) {
      this.$emit('refresh', null, all);
    },
  },
};
</script>

<style lang="scss" scoped>
.price-modal {
  width: 210px;
  min-height: 180px;
}

.modal-header {
  margin-bottom: 10px;
  width: 100%;

  & .input-container {
    width: 100%;
    padding: 0;
  }

  & .input-search {
    width: 100%;
  }
}

.modal-body {
  padding: 10px;
  display: flex;
  width: 100%;
  overflow-y: auto;
}

.modal-footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-top: 15px;
  background-color: white;
  padding: 10px 10px 15px;
}

.apply-btn {
  background-color: #541CAB;
  color: white;
  border: none;
  border-radius: 10px;
  width: 100%;
}

.apply-all-btn {
  color: #541CAB;
  background-color: #EEE8F7;
  border: none;
  border-radius: 15px;
  width: 100%;
}

.price-input {
  height: 35px;
  width: 100%;
  border: 1px solid #541CAB;
  border-radius: 10px;
  padding: 10px;
  margin-top: 15px;
}
</style>
