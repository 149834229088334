<template>
  <div class="manual-cell">
    <div @click="change" v-if="!rowPinned">
      <PencilIcon class="pencil-icon" />
    </div>
    <p>{{ price }}</p>
    <button
      v-if="manual && !rowPinned"
      data-tooltip="Внесены ручные изменения"
      class="button-tooltip warn-icon"
      @click="change"
    >
      <WarnIcon class="warn-icon" />
    </button>
  </div>
</template>

<script>
import WarnIcon from '@/views/reports/report-6/components/icons/WarnIcon.vue';
import PencilIcon from '@/views/reports/report-6/components/icons/PencilIcon.vue';

export default {
  name: 'PriceCell',
  components: { PencilIcon, WarnIcon },
  data() {
    return {
      value: '',
      manual: '',
    };
  },
  methods: {
    change() {
      this.params.openModal(this.params, event);
    },
  },
  watch: {
    'params.data': {
      handler() {
        this.value = this.params.data[this.params.colDef['field']];
        this.manual = this.params.data[this.params.colDef['field_manual']];
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    price() {
      if (this.manual) {
        return this.params.formatter({ value: this.manual });
      }
      return this.params.formatter({ value: this.value });
    },
    rowPinned() {
      return this.params.node.rowPinned === 'bottom';
    },
  },
};
</script>

<style scoped>
.manual-cell {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
    color: #541CAB;
    position: relative;
}

.warn-icon {
    cursor: pointer;
    border: none;
    background-color: transparent;
}

.pencil-icon {
    cursor: pointer;
}

.button-tooltip {
    position: relative;
    display: inline-block;
}

.button-tooltip::after {
    content: attr(data-tooltip);
    position: fixed;
    height: 40px;
    z-index: 10000;
    background-color: #333;
    color: white;
    border-radius: 4px;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transition: none;
}

.button-tooltip:hover::after {
    opacity: 1;
    visibility: visible;
}
</style>
