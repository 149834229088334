<template>
  <div class="filter-sort">
    <div class="actions">
      <b-btn class="import-btn" @click="importModalVisible = true">
        <UploadIcon class="import-icon" />
      </b-btn>
    </div>
    <div class="button-block">
      <b-btn @click="downloadReport" class="wb-btn">
        <img src="@/assets/img/download.svg" />
        <span class="ml-2">Выгрузка в XLSX</span>
      </b-btn>
    </div>
    <ImportModal
      :is-visible="importModalVisible"
      @closeModal="importModalVisible = false"
    />
  </div>
</template>

<script>
import UploadIcon from '@/views/reports/report-6/components/icons/UploadIcon.vue';
import ImportModal from '@/views/reports/report-6/components/modals/ImportModal.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'header',
  components: { ImportModal, UploadIcon },
  data() {
    return {
      importModalVisible: false,
    };
  },
  computed: {
    ...mapGetters(['currentOrganization']),
  },
  methods: {
    ...mapActions(['uploadReport']),
    downloadReport() {
      const filters = {
        wb_account: this.currentOrganization.id,
        export: true,
        excluded_columns: this.getExcludedColumns(),
      };
      this.uploadReport({
        filters: filters,
        url: '/new-reports/unit_economics/',
        reportName: 'Юнит-экономика (план).xlsx',
      });
    },
    getExcludedColumns() {
      const data = JSON.parse(localStorage.getItem('report-6-table-settings'));
      if (data) {
        return data.reduce((acc, col) => {
          const fieldName = col.name ?? col.field;
          return {
            ...acc,
            [fieldName]:
              col.checked === 'false' || col.checked === false || false,
          };
        }, {});
      }
      return {
        wb_club_discount_percent: true,
        wb_club_discount_price: true,
        tariff_constructor: true,
        full_commission: true,
        transportation_tariff_monopalet: true,
        transportation_percent: true,
        keeping_percent: true,
        cost_price_percent: true,
        nds_amount: true,
        tax_amount: true,
        tax_percent_from_sales: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../index.scss';

.filter-sort {
  padding: 20px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background: #fff;
}

.actions {
  display: flex;
  width: 1000px;
  gap: 10px;
  color: #BBA4DE;

  & span {
    width: 1px;
  }
  & p {
    cursor: pointer;

    &:hover {
      color: rebeccapurple;
    }
  }
}

.button-block {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.import-icon {
  fill: white;
  color: white;
}

.import-btn {
  width: 50px;
  height: 46px;
    background: #481173 !important;
  color: #fff !important;
}
</style>
