<template>
  <div class="table">
    <ag-grid-vue
      row-height="50px"
      class="ag-theme-alpine custom-grid"
      style="width: 100%; height: 100%"
      enableCellTextSelection="true"
      :defaultColDef="defaultColDef"
      :columnDefs="columns"
      :rowData="rows.products"
      @grid-ready="onGridReady"
      :pinnedBottomRowData="getPinnedBottomRowData()"
      @filter-changed="onFilterChanged"
    />
    <StoreModal
      :class="storeModal.ModalClasses"
      :is-visible="storeModal.ModalVisible"
      :style="{
        top: storeModal.ModalPosition.top + 'px',
        left: storeModal.ModalPosition.left + 'px',
      }"
      @closeModal="closeStoreModal"
      @setValue="setStore"
      :values="storeModal.ModalValues"
      :value="storeModal.ModalValue"
      :monopalet="storeModal.ModalMonopalet"
    />
    <PriceModal
      :is-visible="basePriceModalVisible"
      @closeModal="closeBasePriceModal"
      @setPrice="setBasePrice"
      @refresh="setBasePrice"
      :style="{
        top: basePriceModal.ModalPosition.top + 'px',
        left: basePriceModal.ModalPosition.left + 'px',
      }"
      :price="basePriceModal.ModalPrice"
      :manualPrice="basePriceModal.ModalManualPrice"
    />
    <NewModalSettings
      v-if="columns.length"
      pageName="report-6"
      :columns="columns"
      @setColumns="setColumns"
      :show-zeros="getShowZeros()"
      @setShowZeros="setShowZeros"
      ref="settingsModal"
    />
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import StoreCell from '@/views/reports/report-6/components/cells/StoreCell.vue';
import StoreModal from '@/views/reports/report-6/components/modals/StoreModal.vue';
import TableFilterModal from '@/components/ui/table/TableFilterModal.vue';
import TableHeaderSettings from '@/components/ui/table/TableHeaderSettings.vue';
import ManualCell from '@/views/reports/report-6/components/cells/ManualCell.vue';
import {
  currencyFormatter,
  percentFormatter,
  percentFormatterFloat,
} from '@/helpers/numberFormatter';
import PriceModal from '@/views/reports/report-6/components/modals/PriceModal.vue';
import { mapActions, mapGetters } from 'vuex';
import NewModalSettings from '@/components/ui/table/NewModalSettings.vue';

export default {
  name: 'Table',
  components: {
    NewModalSettings,
    PriceModal,
    StoreModal,
    AgGridVue,
    StoreCell,
    TableFilterModal,
    TableHeaderSettings,
    ManualCell,
  },
  computed: {
    ...mapGetters('report6', [
      'loading',
      'stores',
      'products',
      'updatedProducts',
    ]),
  },
  data() {
    return {
      columns: [],
      rows: {},
      gridApi: null,
      defaultColDef: {
        resizable: true,
        width: 150,
        filter: false,
        sortable: true,
        unSortIcon: true,
        cellDataType: false,
        alwaysShowHorizontalScroll: true,
        alwaysShowVerticalScroll: true,
        headerComponentParams: {
          pageName: 'report6',
        },
      },
      storeModal: {
        ModalVisible: false,
        ModalPosition: { top: 0, left: 0 },
        ModalValues: [],
        ModalValue: '',
        ModalMonopalet: '',
        ModalProductId: null,
        ModalClasses: [],
        ModalField: '',
      },
      basePriceModal: {
        ModalPosition: { top: 0, left: 0 },
        ModalPrice: '',
        ModalManualPrice: '',
        ModalProductId: null,
        ModalManualPriceField: '',
      },
      basePriceModalVisible: false,
      filteredProductIds: [],
    };
  },
  watch: {
    updatedProducts: {
      handler() {
        if (!this.updatedProducts.length) {
          return;
        }
        this.updatedProducts.forEach((updatedProduct) => {
          const product = this.rows.products.find(
            (product) => product.id === updatedProduct.id
          );
          Object.keys(updatedProduct).forEach((key) => {
            product[key] = updatedProduct[key];
          });
        });
        this.gridApi.refreshCells();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions('report6', [
      'setDefaultOptions',
      'setOptions',
      'updateProducts',
    ]),
    onFilterChanged(params) {
      const displayedRowCount = this.gridApi?.getDisplayedRowCount() || 0;
      if (displayedRowCount !== this.rows.products.length) {
        for (let i = 0; i < displayedRowCount; i++) {
          const rowNode = this.gridApi.getDisplayedRowAtIndex(i);
          this.filteredProductIds.push(rowNode.data.id);
        }
      } else {
        this.filteredProductIds = [];
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.generateTableColumns();
      this.generateTableRows(this.getShowZeros());
    },
    setColumns(columns) {
      this.gridApi.setGridOption('columnDefs', columns);
    },
    setShowZeros(show) {
      if (show) {
        localStorage.setItem('report-6-show-zeros', '1');
      } else {
        localStorage.removeItem('report-6-show-zeros');
      }
      this.generateTableRows(show);
      this.onFilterChanged();
    },
    getShowZeros() {
      return localStorage.getItem('report-6-show-zeros') !== null;
    },
    generateTableColumns() {
      this.columns = [
        {
          headerGroupComponent: 'TableHeaderSettings',
          headerGroupComponentParams: {
            showSettingsModal: this.showSettingsModal,
          },
          children: [
            {
              field: 'store',
              headerName: 'Склад, коэффициент',
              name: 'store',
              cellRenderer: 'StoreCell',
              cellRendererParams: {
                openModal: this.openStoreModal,
              },
            },
            {
              field: 'volume',
              headerName: 'Объём, л',
              name: 'volume',
            },
            {
              field: 'brand',
              headerName: 'Бренд',
              name: 'brand',
              filter: 'TableFilterModal',
            },
            {
              field: 'subject',
              headerName: 'Предмет',
              name: 'subject',
              pinned: 'left',
              filter: 'TableFilterModal',
            },
            {
              field: 'sa_name',
              headerName: 'Артикул продавца',
              name: 'sa_name',
              pinned: 'left',
              filter: 'TableFilterModal',
            },
            {
              field: 'nm_id',
              headerName: 'Артикул WB',
              name: 'nm_id',
              pinned: 'left',
              filter: 'TableFilterModal',
            },
            {
              field: 'stock_today',
              headerName: 'Остатки, итого по складам WB',
              name: 'stock_today',
              width: 200,
            },
            {
              field: 'finishes_in',
              headerName: 'Закончится через, дн',
              name: 'finishes_in',
              width: 200,
              valueGetter: (params) => {
                if (
                  params.data.stock_today > 0 &&
                  params.data.finishes_in === 0
                ) {
                  return 'Нет заказов за 7 дней';
                }
                return params.data.finishes_in;
              },
            },
            {
              field: 'base_price',
              field_manual: 'base_price_manual',
              headerName: 'Базовая цена, до скидок',
              name: 'base_price',
              cellRenderer: 'ManualCell',
              width: 200,
              cellRendererParams: {
                formatter: currencyFormatter,
                openModal: this.openBasePriceModal,
              },
              valueGetter: (params) => {
                return params.data.base_price_manual ?? params.data.base_price;
              },
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return valueA - valueB;
              },
            },
            {
              field: 'discount_percent',
              field_manual: 'discount_percent_manual',
              headerName: 'Скидка продавца, %',
              name: 'discount_percent',
              cellRenderer: 'ManualCell',
              cellRendererParams: {
                formatter: percentFormatter,
                openModal: this.openBasePriceModal,
              },
              valueGetter: (params) => {
                return (
                  params.data.discount_percent_manual ??
                  params.data.discount_percent
                );
              },
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return valueA - valueB;
              },
            },
            {
              field: 'discount_price',
              headerName: 'Цена после скидки (до СПП)',
              name: 'discount_price',
              valueFormatter: currencyFormatter,
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return Number(valueA) - Number(valueB);
              },
              width: 200,
            },
            {
              field: 'spp_percent',
              field_manual: 'spp_percent_manual',
              headerName: 'Размер СПП, %',
              name: 'spp_percent',
              cellRenderer: 'ManualCell',
              cellRendererParams: {
                formatter: percentFormatter,
                openModal: this.openBasePriceModal,
              },
              valueGetter: (params) => {
                return (
                  params.data.spp_percent_manual ?? params.data.spp_percent
                );
              },
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return valueA - valueB;
              },
            },
            {
              field: 'seller_price',
              headerName: 'Цена после СПП, руб',
              name: 'seller_price',
              valueFormatter: currencyFormatter,
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return Number(valueA) - Number(valueB);
              },
              width: 200,
            },
            {
              field: 'price_with_wallet',
              headerName: 'Цена после WB кошелька, руб',
              name: 'price_with_wallet',
              valueFormatter: currencyFormatter,
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return Number(valueA) - Number(valueB);
              },
              width: 200,
            },
            {
              field: 'wb_club_discount_percent',
              headerName: ' ВБ клуб, %',
              name: 'wb_club_discount_percent',
              valueFormatter: percentFormatter,
              checked: false,
            },
            {
              field: 'wb_club_discount_price',
              headerName: 'Цена после ВБ клуба, руб',
              name: 'wb_club_discount_price',
              valueFormatter: currencyFormatter,
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return Number(valueA) - Number(valueB);
              },
              checked: false,
            },
            {
              field: 'paid_storage_kgvp',
              headerName: 'Комиссия ВБ, %',
              name: 'paid_storage_kgvp',
              valueFormatter: percentFormatterFloat,
            },
            {
              field: 'tariff_constructor',
              field_manual: 'tariff_constructor_manual',
              headerName: 'Конструктор тарифов, %',
              name: 'tariff_constructor',
              cellRenderer: 'ManualCell',
              cellRendererParams: {
                formatter: percentFormatterFloat,
                openModal: this.openBasePriceModal,
              },
              valueGetter: (params) => {
                return (
                  params.data.tariff_constructor_manual ??
                  params.data.tariff_constructor
                );
              },
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return valueA - valueB;
              },
              checked: false,
            },
            {
              field: 'acq',
              field_manual: 'acq_manual',
              headerName: 'Эквайринг, %',
              name: 'acq',
              cellRenderer: 'ManualCell',
              cellRendererParams: {
                formatter: percentFormatterFloat,
                openModal: this.openBasePriceModal,
              },
              valueGetter: (params) => {
                return params.data.acq_manual ?? params.data.acq;
              },
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return valueA - valueB;
              },
            },
            {
              field: 'full_commission',
              headerName: 'Общая комиссия, %',
              name: 'full_commission',
              valueFormatter: percentFormatter,
              width: 200,
              checked: false,
            },
            {
              field: 'commission_amount',
              headerName: 'Общая комиссия, руб',
              name: 'commission_amount',
              valueFormatter: currencyFormatter,
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return Number(valueA) - Number(valueB);
              },
            },
            {
              field: 'transportation_tariff',
              headerName: 'Логистика тариф, руб',
              name: 'transportation_tariff',
              valueFormatter: currencyFormatter,
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return Number(valueA) - Number(valueB);
              },
            },
            {
              field: 'monopalet',
              headerName: 'Монопалеты, (вид поставки)',
              name: 'monopalet',
              cellRenderer: 'StoreCell',
              cellRendererParams: {
                openModal: this.openMonoPaletModal,
              },
            },
            {
              field: 'transportation_tariff_monopalet',
              headerName: 'Логистика, тариф по монапаллетам',
              width: 200,
              name: 'transportation_tariff_monopalet',
              valueFormatter: currencyFormatter,
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return Number(valueA) - Number(valueB);
              },
              checked: false,
            },
            {
              field: 'buyouts_percent',
              headerName: 'Процент выкупа, %',
              name: 'buyouts_percent',
              valueFormatter: percentFormatter,
            },
            {
              field: 'transportation_amount',
              headerName: 'Логистика расчёт, руб',
              name: 'transportation_amount',
              valueFormatter: currencyFormatter,
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return Number(valueA) - Number(valueB);
              },
            },
            {
              field: 'transportation_percent',
              width: 200,
              headerName: 'Логистика, % от выручки',
              name: 'transportation_percent',
              valueFormatter: percentFormatter,
              checked: false,
            },
            {
              field: 'keeping_amount',
              headerName: 'Хранение расчёт, руб',
              name: 'keeping_amount',
              valueFormatter: currencyFormatter,
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return Number(valueA) - Number(valueB);
              },
            },
            {
              field: 'keeping_percent',
              width: 200,
              headerName: 'Хранение, % от выручки',
              name: 'keeping_percent',
              valueFormatter: percentFormatter,
              checked: false,
            },
            {
              field: 'cost_price',
              field_manual: 'cost_price_manual',
              headerName: 'Себестоимость товара, руб',
              name: 'cost_price',
              cellRenderer: 'ManualCell',
              width: 200,
              cellRendererParams: {
                formatter: currencyFormatter,
                openModal: this.openBasePriceModal,
              },
              valueGetter: (params) => {
                return params.data.cost_price_manual ?? params.data.cost_price;
              },
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return valueA - valueB;
              },
            },
            {
              field: 'cost_price_percent',
              width: 200,
              headerName: 'Себестоимость, % от выручки',
              name: 'cost_price_percent',
              valueFormatter: percentFormatter,
              checked: false,
            },
            {
              field: 'adv_amount',
              headerName: 'Бюджет на рекламу, руб',
              name: 'adv_amount',
              valueFormatter: currencyFormatter,
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return Number(valueA) - Number(valueB);
              },
              width: 200,
            },
            {
              field: 'adv_percent',
              field_manual: 'adv_percent_manual',
              headerName: 'Бюджет на рекламу, % от выручки',
              name: 'adv_percent',
              cellRenderer: 'ManualCell',
              cellRendererParams: {
                formatter: percentFormatter,
                openModal: this.openBasePriceModal,
              },
              valueGetter: (params) => {
                return (
                  params.data.adv_percent_manual ?? params.data.adv_percent
                );
              },
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return valueA - valueB;
              },
              width: 200,
            },
            {
              field: 'nds_percent',
              field_manual: 'nds_percent_manual',
              headerName: 'НДС, %',
              name: 'nds_percent',
              cellRenderer: 'ManualCell',
              cellRendererParams: {
                formatter: percentFormatter,
                openModal: this.openBasePriceModal,
              },
              valueGetter: (params) => {
                return (
                  params.data.nds_percent_manual ?? params.data.nds_percent
                );
              },
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return valueA - valueB;
              },
            },
            {
              field: 'nds_amount',
              headerName: 'НДС, руб',
              name: 'nds_amount',
              valueFormatter: currencyFormatter,
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return Number(valueA) - Number(valueB);
              },
              checked: false,
            },
            {
              field: 'tax_percent',
              field_manual: 'tax_percent_manual',
              headerName: 'Налог, %',
              name: 'tax_percent',
              cellRenderer: 'ManualCell',
              cellRendererParams: {
                formatter: percentFormatter,
                openModal: this.openBasePriceModal,
              },
              valueGetter: (params) => {
                return (
                  params.data.tax_percent_manual ?? params.data.tax_percent
                );
              },
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return valueA - valueB;
              },
            },
            {
              field: 'tax_amount',
              headerName: 'Налог, руб',
              name: 'tax_amount',
              valueFormatter: currencyFormatter,
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return Number(valueA) - Number(valueB);
              },
              checked: false,
            },
            {
              field: 'tax_percent_from_sales',
              headerName: 'Расчёт процента налога итого',
              name: 'tax_percent',
              valueFormatter: percentFormatter,
              width: 200,
              checked: false,
            },
            {
              field: 'broken_percent',
              field_manual: 'broken_percent_manual',
              headerName: 'Бюджет на брак, %',
              name: 'broken_percent',
              cellRenderer: 'ManualCell',
              cellRendererParams: {
                formatter: percentFormatter,
                openModal: this.openBasePriceModal,
              },
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return Number(valueA) - Number(valueB);
              },
            },
            {
              field: 'broken_amount',
              headerName: 'Брак, руб',
              name: 'broken_amount',
              valueFormatter: currencyFormatter,
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return Number(valueA) - Number(valueB);
              },
            },
            {
              field: 'net_sales',
              headerName: 'Плановая прибыль, руб',
              name: 'net_sales',
              valueFormatter: currencyFormatter,
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return Number(valueA) - Number(valueB);
              },
              cellStyle: (params) => {
                let color = '#93c47d';
                if (+params.data.net_sales === 0) {
                  color = '#9f9f9e';
                } else if (+params.data.net_sales < 0) {
                  color = 'red';
                }
                return {
                  color: color,
                };
              },
              width: 200,
            },
            {
              field: 'margin_percent',
              width: 200,
              headerName: 'Маржинальность, %',
              name: 'margin_percent',
              valueFormatter: percentFormatter,
              cellStyle: (params) => {
                let color = '#93c47d';
                if (+params.data.margin_percent === 0) {
                  color = '#9f9f9e';
                } else if (+params.data.margin_percent < 0) {
                  color = 'red';
                }
                return {
                  color: color,
                };
              },
            },
            {
              field: 'profit_percent',
              width: 250,
              headerName: 'Рентабельность, %',
              name: 'profit_percent',
              valueFormatter: percentFormatter,
              cellStyle: (params) => {
                let color = '#93c47d';
                if (+params.data.profit_percent === 0) {
                  color = '#9f9f9e';
                } else if (+params.data.profit_percent < 0) {
                  color = 'red';
                }
                return {
                  color: color,
                };
              },
            },
            {
              field: 'break_even',
              width: 250,
              headerName: 'Цена для точки безубыточности до СПП',
              name: 'break_even',
              valueFormatter: currencyFormatter,
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return Number(valueA) - Number(valueB);
              },
              cellStyle: (params) => ({
                backgroundColor: '#a9d097',
              }),
            },
          ],
        },
        {
          headerName: 'Прогноз',
          children: [
            {
              field: 'tacos_percent',
              width: 250,
              headerName: 'Прогнозируемый TACoS за прошедшую неделю',
              name: 'tacos_percent',
              valueFormatter: percentFormatter,
            },
            {
              field: 'adv_forecast',
              width: 250,
              headerName: 'Бюджет на рекламу при текущем пр.TACoS',
              name: 'adv_forecast',
              valueFormatter: currencyFormatter,
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return Number(valueA) - Number(valueB);
              },
            },
            {
              field: 'profit_per_unit',
              width: 250,
              headerName: 'Прибыль на ед, при текущем  пр.TACoS',
              name: 'profit_per_unit',
              valueFormatter: currencyFormatter,
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return Number(valueA) - Number(valueB);
              },
            },
            {
              field: 'margin_forecast',
              width: 250,
              headerName: 'Маржинальность, при текущем  пр.TACoS',
              name: 'margin_forecast',
              valueFormatter: percentFormatter,
            },
            {
              field: 'rent_forecast',
              width: 250,
              headerName: 'Рентабельность, при текущем  пр.TACoS',
              name: 'rent_forecast',
              valueFormatter: percentFormatter,
            },
          ],
        },
        {
          headerName: 'Партия',
          children: [
            {
              field: 'cost_of_stock',
              width: 250,
              headerName: 'Себестоимость остатков, руб',
              name: 'cost_of_stock',
              valueFormatter: currencyFormatter,
              comparator: (valueA, valueB, nodeA, nodeB) => {
                return Number(valueA) - Number(valueB);
              },
            },
            {
              field: 'profit_of_stock',
              width: 250,
              headerName: 'Выручка с партии',
              name: 'profit_of_stock',
              valueFormatter: currencyFormatter,
            },
            {
              field: 'to_transfer',
              width: 250,
              headerName: 'К перечислению, руб',
              name: 'to_transfer',
              valueFormatter: currencyFormatter,
            },
          ],
        },
      ];
    },
    getPinnedBottomRowData() {
      let products = this.rows?.products || [];
      if (this.filteredProductIds.length > 0) {
        products = products.filter((el) =>
          this.filteredProductIds.includes(el.id)
        );
      }
      const total_stock_today =
        products.reduce((sum, item) => sum + item.stock_today, 0) || 0;
      const total_profit_of_stock =
        products?.reduce(
          (sum, item) => sum + Number(item.profit_of_stock),
          0
        ) || 0;
      const total_to_transfer =
        products?.reduce((sum, item) => sum + Number(item.to_transfer), 0) || 0;
      const total_cost_of_stock =
        products?.reduce((sum, item) => sum + Number(item.cost_of_stock), 0) ||
        0;

      return [
        {
          volume: Number(this.getAvgByField(products, 'volume')).toFixed(2),
          stock_today: total_stock_today,
          base_price: this.getAvgByField(products, 'base_price'),
          discount_price: this.getAvgByField(products, 'discount_price'),
          seller_price: this.getAvgByField(products, 'seller_price'),
          price_with_wallet: this.getAvgByField(products, 'price_with_wallet'),
          wb_club_discount_percent: this.getAvgByField(
            products,
            'wb_club_discount_percent'
          ),
          wb_club_discount_price: this.getAvgByField(
            products,
            'wb_club_discount_price'
          ),
          commission_amount: this.getAvgByField(products, 'commission_amount'),
          transportation_tariff: this.getAvgByField(
            products,
            'transportation_tariff'
          ),
          transportation_tariff_monopalet: this.getAvgByField(
            products,
            'transportation_tariff_monopalet'
          ),
          transportation_amount: this.getAvgByField(
            products,
            'transportation_amount'
          ),
          keeping_amount: this.getAvgByField(products, 'keeping_amount'),
          cost_price: this.getAvgByField(products, 'cost_price'),
          adv_amount: this.getAvgByField(products, 'adv_amount'),
          tax_amount: this.getAvgByField(products, 'tax_amount'),
          nds_amount: this.getAvgByField(products, 'nds_amount'),
          net_sales: this.getAvgByField(products, 'net_sales'),
          broken_amount: this.getAvgByField(products, 'broken_amount'),
          adv_forecast: this.getAvgByField(products, 'adv_forecast'),
          profit_per_unit: this.getAvgByField(products, 'profit_per_unit'),
          profit_of_stock: total_profit_of_stock,
          to_transfer: total_to_transfer,
          cost_of_stock: total_cost_of_stock,

          discount_percent: this.getAvgByField(products, 'discount_percent'),
          spp_percent: this.getAvgByField(products, 'spp_percent'),
          paid_storage_kgvp: this.getAvgByField(products, 'paid_storage_kgvp'),
          acq: this.getAvgByField(products, 'acq'),
          full_commission: this.getAvgByField(products, 'full_commission'),
          buyouts_percent: this.getAvgByField(products, 'buyouts_percent'),
          transportation_percent: this.getAvgByField(
            products,
            'transportation_percent'
          ),
          keeping_percent: this.getAvgByField(products, 'keeping_percent'),
          cost_price_percent: this.getAvgByField(
            products,
            'cost_price_percent'
          ),
          adv_percent: this.getAvgByField(products, 'adv_percent'),
          nds_percent: this.getAvgByField(products, 'nds_percent'),
          tax_percent: this.getAvgByField(products, 'tax_percent'),
          tax_percent_from_sales: this.getAvgByField(
            products,
            'tax_percent_from_sales'
          ),
          broken_percent: this.getAvgByField(products, 'broken_percent'),
          margin_percent: this.getAvgByField(products, 'margin_percent'),
          profit_percent: this.getAvgByField(products, 'profit_percent'),
          break_even: this.getAvgByField(products, 'break_even'),
          tacos_percent: this.getAvgByField(products, 'tacos_percent'),
          margin_forecast: this.getAvgByField(products, 'margin_forecast'),
          rent_forecast: this.getAvgByField(products, 'rent_forecast'),
        },
      ];
    },
    getAvgByField(products, field) {
      const field_manual = `${field}_manual`;
      let total =
        products
          ?.filter((item) => {
            if (Number(item.stock_today) === 0) {
              return false;
            }
            const base_price = Number(
              item.base_price_manual || item.base_price
            );
            if (base_price === 0) {
              return Number(item[field_manual] || item[field]) !== 0;
            } else {
              return item;
            }
          })
          .reduce(
            (acc, item, _, filteredArray) => {
              const price = Number(item[field_manual] || item[field]);
              acc.sum += price;
              acc.count++;
              if (acc.count === filteredArray.length) {
                return acc.sum / acc.count;
              }
              return acc;
            },
            { sum: 0, count: 0 }
          ) || 0;
      if (total.sum !== undefined) {
        total = total.sum;
      }
      return total;
    },
    generateTableRows(showZeros = false) {
      this.rows = {
        products: this.products.filter((el) => {
          if (!showZeros) {
            return el.stock_today > 0;
          }
          return true;
        }),
      };
    },
    getModalData(params, event) {
      const cellRect = event.target.getBoundingClientRect();
      return {
        ModalVisible: true,
        ModalPosition: {
          top: cellRect.top + window.scrollY + cellRect.height,
          left: cellRect.left + window.scrollX + 80,
        },
        ModalProductId: params.data.id,
      };
    },
    openStoreModal(params, event) {
      this.storeModal = this.getModalData(params, event);
      this.storeModal.ModalValue = params.data.store;
      this.storeModal.ModalValues = this.stores;
      this.storeModal.ModalClasses = ['store-modal'];
      this.storeModal.ModalField = 'store';
      this.storeModal.ModalMonopalet = params.data.monopalet;
    },
    closeStoreModal() {
      this.storeModal.ModalVisible = false;
    },
    async setStore(store, all) {
      const products = [];
      if (all) {
        this.rows.products.forEach((product) => {
          product[this.storeModal.ModalField] = store;
          products.push({
            id: product.id,
            field: this.storeModal.ModalField,
            value: store,
          });
        });
      } else {
        const product = this.rows.products.find(
          (product) => product.id === this.storeModal.ModalProductId
        );
        product[this.storeModal.ModalField] = store;
        products.push({
          id: product.id,
          field: this.storeModal.ModalField,
          value: store,
        });
      }
      this.updateProducts({ products: products });
      this.gridApi.refreshCells();
      this.closeStoreModal();
    },
    openMonoPaletModal(params, event) {
      this.storeModal = this.getModalData(params, event);
      this.storeModal.ModalPosition.left -= 220;
      this.storeModal.ModalValue = params.data.monopalet;
      this.storeModal.ModalValues = ['Да', 'Нет'];
      this.storeModal.ModalField = 'monopalet';
    },
    openBasePriceModal(params, event) {
      let modalData = this.getModalData(params, event);
      modalData.ModalPrice = params.data[params.colDef['field']];
      modalData.ModalManualPrice = params.data[params.colDef['field_manual']];
      modalData.ModalManualPriceField = params.colDef['field_manual'];
      modalData.ModalPosition.left -= 280;
      this.basePriceModal = modalData;
      this.basePriceModalVisible = true;
    },
    closeBasePriceModal() {
      this.basePriceModalVisible = false;
      this.basePriceModal = {
        ModalPosition: { top: 0, left: 0 },
        ModalPrice: '',
        ModalManualPrice: '',
        ModalProductId: null,
        ModalManualPriceField: '',
      };
    },
    setBasePrice(price, all) {
      const products = [];
      if (all) {
        this.rows.products.forEach((product) => {
          product[this.basePriceModal.ModalManualPriceField] = +price;
          products.push({
            id: product.id,
            field: this.basePriceModal.ModalManualPriceField,
            value: price,
          });
        });
      } else {
        const product = this.rows.products.find(
          (product) => product.id === this.basePriceModal.ModalProductId
        );
        product[this.basePriceModal.ModalManualPriceField] = +price;
        products.push({
          id: product.id,
          field: this.basePriceModal.ModalManualPriceField,
          value: price,
        });
      }
      this.updateProducts({ products: products });
      this.gridApi.refreshCells();
      this.closeBasePriceModal();
    },
    showSettingsModal() {
      this.$refs.settingsModal.open();
    },
  },
};
</script>

<style lang="scss">
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-alpine.css';

.table,
.custom-grid .ag-root {
  height: calc(100vh - 230px);
}

.custom-grid .ag-header {
  background-color: #f8f8f8;
  border-bottom: 2px solid #ccc;
}

.custom-grid .ag-row {
  font-size: 14px;
  border-bottom: 1px solid #f1f1f1;
  height: 32px;

  &:nth-child(2n) {
    background-color: #fafafa;
  }
}

.custom-grid .ag-row:hover {
  background-color: #f1f1f1;
}

.custom-grid .ag-cell {
  text-align: center;
  font-size: 12px;
}

.custom-grid .ag-header-cell {
  font-weight: 500;
  text-align: center;
  background-color: #fcf8ff;
  color: #908f90;
  font-size: 14px;
  white-space: wrap;
}

.custom-grid {
  .ag-root-wrapper {
    border: 1px solid #f1f1f1;

    &-body {
      padding: 0;
    }
  }

  .ag-header-container {
    .table-v2-btn-settings {
      display: none;
    }
  }

  .ag-header {
    border-bottom: 1px solid #f1f1f1;
  }

  .ag-pinned-left-header {
    border-right: none;
  }

  .ag-header-group-cell-label {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ag-header-group-text {
    color: #908f90;
    font-size: 12px;
  }

  .ag-header-cell,
  .ag-header-cell-label {
    font-weight: 500;
    text-align: center;
    background-color: #fcf8ff;
    color: #908f90;
    font-size: 12px;
    white-space: wrap;
  }

  .ag-header-row:not(:first-child)
  .ag-header-cell:not(.ag-header-span-height.ag-header-span-total),
  .ag-floating-bottom,
  .ag-root-wrapper {
    border-top: 1px solid #f1f1f1;
  }

  .ag-cell {
    &.ag-cell-last-left-pinned {
      &:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
        border-right: none;
      }
    }
  }
}

.border .custom-grid .ag-group-cell {
  font-weight: bold;
  background-color: #f2f2f2;
}

.custom-grid .ag-header-group-cell-label {
  margin: auto;
}

.ag-header-row {
  height: 42px;
}

.store-modal {
  width: 300px;
  height: 330px;
}
</style>
