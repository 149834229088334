<template>
  <div>
    <p class="store" @click="change">{{ val }}</p>
  </div>
</template>

<script>
export default {
  name: 'StoreCell',
  data() {
    return {
      selectedValue: '',
    };
  },
  beforeMount() {
    this.selectedValue = this.params.data[this.params.colDef['field']];
  },
  methods: {
    change() {
      this.params.openModal(this.params, event);
    },
  },
  computed: {
    val() {
      return this.selectedValue === true
        ? 'Да'
        : this.selectedValue === false
        ? 'Нет'
        : this.selectedValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.store {
  color: #541CAB;
  cursor: pointer;
}
</style>
