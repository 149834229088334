<template>
  <div class="h-100 default-layout d-flex flex-column fix-width">
    <b-navbar
      v-if="$route.path !== '' || $route.path !== '/landing'"
      class="wb-header p-0 dashboard__navbar"
    >
      <b-container fluid class="px-5 h-100">
        <b-navbar-brand href="/" class="p-0">
          <b-img src="/img/logo.svg" width="121" height="40"></b-img>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav class="pl-4 h-100">
          <b-navbar-nav class="h-100">
            <b-nav-item :to="{ name: 'dashboard' }"> Дашборд</b-nav-item>
            <b-nav-item :to="{ name: 'organizations' }"
              >Организации
            </b-nav-item>
            <b-nav-item-dropdown right no-caret class="reports-dropdown">
              <template #button-content>
                Отчеты
                <img
                  src="/img/chevron-down.svg"
                  class="ml-2 wb-dropdown-icon"
                />
              </template>
              <b-dropdown-item
                :class="[
                  current_report === 'productsAdvertising' ? 'active-item' : '',
                ]"
                @click="goToReport('productsAdvertising')"
                >1. Все товары в рекламе
              </b-dropdown-item>
              <b-dropdown-item
                :class="[
                  current_report === 'CompaniesAdvertising'
                    ? 'active-item'
                    : '',
                ]"
                @click="goToReport('CompaniesAdvertising')"
                >2. Все рекламные кампании
              </b-dropdown-item>
              <b-dropdown-item
                :class="[current_report === 'dyn_orders' ? 'active-item' : '']"
                @click="goToReport('dyn_orders')"
                >5. Отчёт РнП
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item :to="{ name: 'Report6' }">Юнит-экономика </b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <div class="wb-inactive" v-if="showTokenExpired">
              <div>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.3334 6.99996C12.3334 9.94548 9.94554 12.3333 7.00002 12.3333C4.0545 12.3333 1.66669 9.94548 1.66669 6.99996C1.66669 4.05444 4.0545 1.66663 7.00002 1.66663C9.94554 1.66663 12.3334 4.05444 12.3334 6.99996ZM7.00002 13.6666C10.6819 13.6666 13.6667 10.6819 13.6667 6.99996C13.6667 3.31806 10.6819 0.333293 7.00002 0.333293C3.31812 0.333293 0.333355 3.31806 0.333355 6.99996C0.333355 10.6819 3.31812 13.6666 7.00002 13.6666ZM7.66669 9.99996C7.66669 10.3681 7.36821 10.6666 7.00002 10.6666C6.63183 10.6666 6.33335 10.3681 6.33335 9.99996C6.33335 9.63177 6.63183 9.33329 7.00002 9.33329C7.36821 9.33329 7.66669 9.63177 7.66669 9.99996ZM6.33335 3.66663L6.33335 8.33329L7.66669 8.33329L7.66669 3.66663L6.33335 3.66663Z"
                    fill="#F44336"
                  />
                </svg>
              </div>
              <div>Неактивна. Истёк токен</div>
            </div>
            <div
              class="wb-inactive"
              v-else-if="
                currentOrganization.id && !currentOrganization.is_active
              "
            >
              <div>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.3334 6.99996C12.3334 9.94548 9.94554 12.3333 7.00002 12.3333C4.0545 12.3333 1.66669 9.94548 1.66669 6.99996C1.66669 4.05444 4.0545 1.66663 7.00002 1.66663C9.94554 1.66663 12.3334 4.05444 12.3334 6.99996ZM7.00002 13.6666C10.6819 13.6666 13.6667 10.6819 13.6667 6.99996C13.6667 3.31806 10.6819 0.333293 7.00002 0.333293C3.31812 0.333293 0.333355 3.31806 0.333355 6.99996C0.333355 10.6819 3.31812 13.6666 7.00002 13.6666ZM7.66669 9.99996C7.66669 10.3681 7.36821 10.6666 7.00002 10.6666C6.63183 10.6666 6.33335 10.3681 6.33335 9.99996C6.33335 9.63177 6.63183 9.33329 7.00002 9.33329C7.36821 9.33329 7.66669 9.63177 7.66669 9.99996ZM6.33335 3.66663L6.33335 8.33329L7.66669 8.33329L7.66669 3.66663L6.33335 3.66663Z"
                    fill="#F44336"
                  />
                </svg>
              </div>
              <div>Неактивна</div>
            </div>
            <div class="token-expires" v-else-if="showTokenExpires">
              <div>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.3334 6.99996C12.3334 9.94548 9.94554 12.3333 7.00002 12.3333C4.0545 12.3333 1.66669 9.94548 1.66669 6.99996C1.66669 4.05444 4.0545 1.66663 7.00002 1.66663C9.94554 1.66663 12.3334 4.05444 12.3334 6.99996ZM7.00002 13.6666C10.6819 13.6666 13.6667 10.6819 13.6667 6.99996C13.6667 3.31806 10.6819 0.333293 7.00002 0.333293C3.31812 0.333293 0.333355 3.31806 0.333355 6.99996C0.333355 10.6819 3.31812 13.6666 7.00002 13.6666ZM7.66669 9.99996C7.66669 10.3681 7.36821 10.6666 7.00002 10.6666C6.63183 10.6666 6.33335 10.3681 6.33335 9.99996C6.33335 9.63177 6.63183 9.33329 7.00002 9.33329C7.36821 9.33329 7.66669 9.63177 7.66669 9.99996ZM6.33335 3.66663L6.33335 8.33329L7.66669 8.33329L7.66669 3.66663L6.33335 3.66663Z"
                    fill="#FF9500"
                  />
                </svg>
              </div>
              <div>
                Токен истекает
                {{
                  formatDate(currentOrganization.token_expire_date, 'DD.MM.YY')
                }}
              </div>
            </div>
          </b-navbar-nav>
          <b-navbar-nav class="h-100">
            <b-nav-item-dropdown right no-caret class="nav_item_dropdown">
              <template #button-content>
                <div v-if="users[0] && currentOrganization">
                  <p>{{ currentOrganization.fullname }}</p>
                  <p class="wb-parse-date">
                    Обновлено:
                    {{
                      formatDate(
                        currentOrganization.parse_date,
                        'DD.MM.YY в HH:mm'
                      )
                    }}
                  </p>
                </div>
                <span class="truncate-text" v-else>Ваши организации</span>
                <img
                  src="/img/chevron-down.svg"
                  class="ml-3 wb-dropdown-icon"
                />
              </template>
              <b-dropdown-item
                @click="selectUser(user)"
                v-for="user of users"
                :key="user.id"
                href="#"
                >{{ user.fullname }}
              </b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown right no-caret class="user-actions">
              <template #button-content>
                <b-img src="/img/hat.png" width="48" height="48" />
                <img
                  src="/img/chevron-down.svg"
                  class="ml-3 wb-dropdown-icon"
                />
              </template>
              <b-dropdown-item>
                <router-link to="/profile" class="link_profile">
                  Профиль
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item class="logout" href="#" @click="logout"
                >Выйти
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>

    <main class="wb-main flex-grow-1 d-flex flex-column">
      <b-container fluid class="px-4 d-flex flex-column flex-grow-1">
        <div class="flex-grow-1" id="content">
          <b-container fluid>
            <router-view />
          </b-container>
        </div>

        <hr class="wb-footer-hr" id="end-page" />

        <footer class="wb-footer d-flex">
          <div class="flex-grow-1">
            Есть вопросы? Нашли баг? Нужна доработка? — Пишите в telegram
            <a href="https://t.me/+CMS7NzvwMtIxMWZi" target="_blank"
              >wblead
              <b-icon-telegram />
            </a>
          </div>

          <div class="copyright">
            WBLead | {{ new Date().getFullYear() }} &copy;
          </div>
        </footer>
      </b-container>
    </main>
    <TariffUpPopup />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import dayjs from 'dayjs';
import { formatDate } from '../helpers/numberFormatter';
import TariffUpPopup from '@/views/TariffUpPopup.vue';

export default {
  name: 'DefaultLayout',
  components: { TariffUpPopup },
  data() {
    return {
      searchValue: '',
      from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      current_report: '',
      isLoadingUsers: true,
    };
  },
  methods: {
    formatDate,
    ...mapMutations(['SET_CURRENT_ORGANIZATION']),
    ...mapActions(['getUsers', 'getProductStatistic', 'getProfileInfo']),
    ...mapMutations('auth', ['LOGOUT']),
    ...mapMutations(['SET_CLEAN_ORG']),
    goToReport(name) {
      this.current_report = name;
      switch (name) {
        case 'productsStatistic':
          this.$router.push('/reports/products-statistic');
          break;

        case 'dyn_orders':
          this.$router.push('/reports/dyn-products');
          break;

        case 'unitEconomics':
          this.$router.push('/reports/unit-economics');
          break;

        default:
          this.$router.push({ name: name });
      }
    },

    clearAllStorage() {
      const listStores = [
        'WBLeadFilter-products-advertising',
        'WBLeadColumnproductsAdvertising',
        'WBLeadColumnSortproductsAdvertising',
        'WBLeadFilter-companies-advertising',
      ];

      for (let store of listStores) {
        localStorage.removeItem(store);
      }
    },

    selectUser(user) {
      this.clearAllStorage();
      localStorage.setItem('organization', JSON.stringify(user));
      window.location.reload();
    },
    logout() {
      this.LOGOUT();
      this.SET_CLEAN_ORG();
      this.$router.push('auth/login');
    },
    async searchHandler() {
      let params = {
        account_id: 1,
        start_date: this.from,
        end_date: this.to,
        nm_id: this.searchValue,
      };
      if (!this.$route.query.id) {
        await this.getProductStatistic(params);
        this.$router.push({
          path: `/reports/products-statistic/${this.searchValue}`,
          query: { id: this.searchValue },
        });
        this.$forceUpdate();
      } else {
        this.$router.push({
          path: `/reports/products-statistic/${this.searchValue}`,
          query: { id: this.searchValue },
        });
      }
    },
  },
  watch: {
    '$route.path'() {
      if (
        this.currentOrganization.id &&
        !this.currentOrganization.tokens_state?.wb_api_token
      ) {
        this.$notify({
          type: 'error',
          duration: 10000,
          text: 'API токен этого кабинета истёк. Обновите его, пожалуйста. <a href="https://lk.wblead.ru/organizations">Обновить</a>',
        });
      }
    },
    currentUserTariffInfo(currentUserTariffInfo) {
      if (
        currentUserTariffInfo &&
        !currentUserTariffInfo.has_active_subscription
      ) {
        this.$notify({
          type: 'error',
          duration: 10000,
          text: 'Ваша подписка истекла. Продлите её пожалуйста, во вкладке Тарифы. <a href="https://lk.wblead.ru/tariffs">Продлить</a>',
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      'users',
      'currentUser',
      'currentUserTariffInfo',
      'currentOrganization',
    ]),
    ...mapGetters('auth', ['isAuthenticated']),
    showTokenExpires() {
      if (!this.currentOrganization.id) {
        return false;
      }
      const expireDate = dayjs(this.currentOrganization.token_expire_date);
      const today = dayjs();
      const diff = expireDate.diff(today, 'day');
      return diff >= 0 && diff <= 7;
    },
    showTokenExpired() {
      if (!this.currentOrganization.id) {
        return false;
      }
      const expireDate = dayjs(this.currentOrganization.token_expire_date);
      const today = dayjs();
      const diff = expireDate.diff(today, 'day');
      return diff < 0;
    },
  },
  async mounted() {
    if (this.isAuthenticated) {
      const usersArr = await this.getUsers({ options: { isStart: true } });
      await this.getProfileInfo();

      if (this.currentOrganization.id === 1 && usersArr[0]) {
        this.selectUser(usersArr[0]);
      }
    }
    this.isLoadingUsers = false;
  },
};
</script>

<style scoped>
.input-search {
    background: white !important;
    border: 1px solid #f1f1f1;
    height: 42px;
    width: 240px;
    border-radius: 10px;
}

.input-container {
    position: relative;
}

.fix-width {
    max-width: 100vw;
    box-sizing: border-box;
    overflow: hidden;
}

.input-icon {
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust the left value to position the icon as desired */
    transform: translateY(-50%);
    color: #999; /* You can change the color of the icon to match the input placeholder */
}

.active-item {
    border-bottom: 2px solid #481173;
}
</style>
<style lang="scss">
.dropdown-item:active {
  background-color: white !important;
  color: #481173 !important;
}

.truncate-text {
  display: inline-block;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nav_item_dropdown .dropdown-menu {
  min-height: 50px;
  max-height: 300px;
  overflow-y: auto;
}

.link_profile {
  color: #160027 !important;
  border-bottom: none !important;
}

.notification-content > a {
  color: #42aaff !important;
  text-decoration: none !important;

  &:hover {
    text-decoration: underline !important;
  }
}

.wb-inactive {
  padding: 10px;
  border-radius: 5px;
  color: rgba(255, 45, 85, 1);
  background-color: rgba(255, 45, 85, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  & div:first-child {
    display: flex;
  }
}
.token-expires {
  padding: 10px;
  border-radius: 5px;
  color: rgba(255, 149, 0, 1);
  background-color: rgba(255, 149, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  & div:first-child {
    display: flex;
  }
}

.wb-parse-date {
  font-size: 14px;
}
</style>
