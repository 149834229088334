export const mutations = {
  REPORT6_SET_LOADING(state, payload) {
    state.loading = payload;
  },

  REPORT5_TO_INITIAL_VALUES(state) {
    state.items = [];
    state.totalsItems = {
      reports_totals: {},
      products_totals: {},
    };
  },

  REPORT6_SET_PRODUCTS(state, payload) {
    state.products = payload;
  },
  REPORT6_SET_UPDATED_PRODUCTS(state, payload) {
    state.updatedProducts = payload;
  },
  REPORT6_SET_STORES(state, payload) {
    state.stores = payload;
  },

  REPORT5_SET_TOTAL_ITEMS(state, payload) {
    state.totalsItems = payload;
  },

  SET_OPTIONS(state, payload) {
    state.options = payload;
  },
  SET_SUITABLE_SUBSCRIPTION(state, payload) {
    state.hasProductsSuitableSubscription = payload;
  },
};
