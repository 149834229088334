<script>
import { mapActions } from 'vuex';

import PaymentMethod from '@/components/profile/PaymentMethod.vue';
import PaymentHistory from '@/components/profile/PaymentHistory.vue';
import PaymentRedirectPopup from '@/components/profile/PaymentRedirectPopup.vue';
export default {
  name: 'PaymentMethods',

  components: {
    PaymentMethod,
    PaymentHistory,
    PaymentRedirectPopup,
  },

  props: {
    methods: {
      type: Array,
      required: false,
      default: [],
    },
    addAccount: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isPaymentPopupOpened: false,
    };
  },

  methods: {
    ...mapActions(['getProfileInfo']),

    handleUpdate() {
      this.getProfileInfo();
    },
  },
};
</script>

<template>
  <div class="payment_methods">
    <h6 class="payment_methods__title">Способ оплаты</h6>

    <div class="payment_methods__list">
      <PaymentMethod
        v-for="method in methods"
        :key="method.id"
        :method="method"
        :onUpdate="handleUpdate"
      />
    </div>

    <div class="payment_actions">
      <button class="apply-btn" @click="addAccount">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1940_5278)">
            <path
              d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
              stroke="white"
              stroke-width="1.5"
              stroke-miterlimit="10"
            />
            <path
              d="M8.25 12H15.75"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 8.25V15.75"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1940_5278">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span>Добавить способ оплаты</span>
      </button>
      <button
        @click="() => (isPaymentPopupOpened = true)"
        class="apply-btn apply-btn--light"
      >
        <span>Оплатить на расчётный счёт</span>
      </button>
      <div class="payment_title">
        🔥 Скидка 5-15% при оплате за 3-12 месяцев
      </div>
    </div>
    <div class="payment_methods__history">
      <PaymentHistory />
    </div>
    <PaymentRedirectPopup
      :is-opened="isPaymentPopupOpened"
      @close="() => (isPaymentPopupOpened = false)"
    />
  </div>
</template>

<style scoped lang="scss">
.payment_methods {
  &__title {
    margin: 0;
    padding: 0;
    color: #908F90;
    font-size: 16px;
    font-weight: 400;
  }

  &__list {
    margin: 1rem 0;
  }

  &__add {
    color: #908F90;
  }

  &__history {
    margin-top: 36px;
  }
}

.payment_methods__add__container {
  display: flex;
  gap: 12px;
  align-items: center;
}

.payment_actions {
  display: flex;
  gap: 10px;

  & .payment_title {
    display: flex;
    align-items: center;
    color: #481173;
  }
}
.apply-btn {
  background-color: #541CAB;
  color: white;
  border: none;
  border-radius: 10px;
    width: 270px;
    height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  & img {
    color: white;
    fill: white;
  }
}

.apply-btn--light {
  background-color: #E9DBF3;
  color: #481173;
  text-decoration: none;
}
</style>
