export const mutations = {
  REPORT5_SET_LOADING(state, payload) {
    state.loading = payload;
  },

  REPORT5_TO_INITIAL_VALUES(state) {
    state.items = [];
    state.totalsItems = {
      reports_totals: {},
      products_totals: {},
    };
  },

  REPORT5_SET_ITEMS(state, payload) {
    state.items = payload;
  },

  REPORT5_SET_TOTAL_ITEMS(state, payload) {
    state.totalsItems = payload;
  },

  SET_OPTIONS(state, payload) {
    state.options = payload;
  },

  SET_FILTERS(state, payload) {
    state.filters = payload;
  },
  SET_SUITABLE_SUBSCRIPTION(state, payload) {
    state.hasSuitableSubscription = payload;
  },
};
