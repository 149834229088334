<template>
  <div class="companiesAdvertising">
    <div class="companiesAdvertising-filter wrap_page_filter">
      <filterAndSort></filterAndSort>
    </div>

    <div
      class="companiesAdvertising-table"
      v-if="isHasSubscribe && hasCompaniesSuitableSubscribtion"
    >
      <b-skeleton-table
        :rows="5"
        v-if="loading"
        :columns="4"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
      <div v-if="!loading">
        <Table v-if="items.length" :totalsItems="totalsItems" :items="items" />
        <div v-else>
          <NoData />
        </div>
      </div>
    </div>
    <PartEmpty v-else :notSuitableTariff="!hasCompaniesSuitableSubscribtion" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import filterAndSort from './components/filterAndSort';
import NoData from '@/components/no-data/NoData.vue';
import PartEmpty from '@/components/empty/PartEmpty.vue';
import Table from './components/Table.vue';

export default {
  name: 'index',
  components: {
    PartEmpty,
    NoData,
    filterAndSort,
    Table,
  },
  data() {
    return {
      pageName: 'companiesAdvertising',
    };
  },
  computed: {
    ...mapGetters(['currentOrganization', 'isHasSubscribe']),
    ...mapGetters('companiesAdvertising', [
      'items',
      'loading',
      'totalsItems',
      'hasCompaniesSuitableSubscribtion',
      'options',
    ]),
  },

  methods: {
    ...mapActions('companiesAdvertising', ['setDefaultOptions', 'getData']),
  },
  mounted() {
    if (this.currentOrganization.id === null) {
      return;
    }
    this.setDefaultOptions(this.currentOrganization.id);
    this.getData();
  },
};
</script>

<style lang="scss" scoped></style>
