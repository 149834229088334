<template>
  <div class="table-filter-modal">
    <template v-if="filterValues">
      <div class="input-container">
        <b-icon
          icon="search"
          class="input-icon input-container__search"
        ></b-icon>
        <b-form-input
          v-model="searchValue"
          @input="searchFilterItems"
          class="input-search"
          placeholder="Поиск"
        ></b-form-input>
      </div>

      <div class="checkboxes-content" @click.stop>
        <b-form-checkbox
          id="checkbox-1"
          class="modal-checkbox"
          @change="onAllChecked"
          v-model="checkedAll"
        >
          Все
        </b-form-checkbox>
        <b-form-checkbox
          v-for="filterValue in searchedFilter"
          :key="filterValue.value"
          class="modal-checkbox"
          @change="onCheckboxChanged(filterValue)"
          v-model="filterValue.isChecked"
        >
          {{ filterValue.value }}
        </b-form-checkbox>
      </div>

      <div class="d-flex justify-content-end mt-3">
        <button class="modal-btn-cancel" @click.prevent="resetFilter">
          {{ isFilterActive() ? 'Очистить' : 'Отмена' }}
        </button>
        <button class="ml-2 modal-btn-submit" @click.prevent="applyFilter">
          Применить
        </button>
      </div>
    </template>
  </div>
</template>
<script>
import debounce from 'debounce';
export default {
  name: 'TableFilterModal',
  data() {
    return {
      filterValues: null,
      selectedValues: [],
      checkedAll: true,
      searchedFilter: null,
      params: null,
      searchValue: '',
    };
  },
  mounted() {
    this.initColumnData();
  },
  methods: {
    initColumnData() {
      let values = new Set();
      this.params.api.forEachNode((node) => {
        if (node.data && node.data[this.params.colDef.field] !== undefined) {
          values.add(node.data[this.params.colDef.field]);
        }
      });
      this.selectedValues = Array.from(values);
      this.searchedFilter = this.filterValues = this.selectedValues.map(
        (value) => ({
          value,
          isChecked: true,
        })
      );

      setTimeout(() => {
        this.checkedAll =
          this.filterValues.length === this.selectedValues.length;
        this.searchedFilter = this.filterValues = this.filterValues.map(
          ({ value }) => ({
            value,
            isChecked: this.selectedValues.includes(value),
          })
        );
      }, 0);
    },

    onCheckboxChanged(filterValue) {
      const value = filterValue.value;
      if (filterValue.isChecked) {
        this.selectedValues.push(filterValue.value);
        this.checkedAll = !this.isFilterActive();
      } else {
        this.selectedValues = this.selectedValues.filter(
          (selectedValue) => selectedValue !== value
        );
        this.checkedAll = false;
      }
    },

    doesFilterPass(params) {
      const value = params.data[this.params.colDef.field];
      return this.selectedValues.includes(value);
    },

    setCheckedValue(value) {
      this.filterValues = this.filterValues.map((filterValue) => {
        return {
          ...filterValue,
          isChecked: value,
        };
      });
      this.searchedFilter = this.searchedFilter.map((searchedItem) => {
        return {
          ...searchedItem,
          isChecked: value,
        };
      });
    },

    onAllChecked(value) {
      if (value) {
        this.selectedValues = this.filterValues.map(
          (filterValue) => filterValue.value
        );
        this.setCheckedValue(true);
      } else {
        this.selectedValues = [];
        this.setCheckedValue(false);
      }
    },

    isFilterActive() {
      return this.selectedValues.length < this.filterValues.length;
    },

    getModel() {
      if (!this.isFilterActive()) {
        return null;
      }
      return { selectedValues: this.selectedValues };
    },
    setModel(model) {
      if (model) {
        this.selectedValues = model.selectedValues;
      } else {
        this.selectedValues = [];
      }
    },

    searchFilterItems: debounce(function (searchValue) {
      this.searchedFilter = this.filterValues.filter((filterValue) =>
        ('' + filterValue.value).toLowerCase().includes(searchValue)
      );
    }, 300),

    resetFilter() {
      this.filterValues = this.filterValues.map((value) => ({
        ...value,
        isChecked: true,
      }));
      this.searchedFilter = this.filterValues;
      this.selectedValues = this.filterValues.map(({ value }) => value);
      this.checkedAll = true;
      this.searchValue = '';
      this.params.filterChangedCallback();
      this.params.api.hidePopupMenu();
    },

    applyFilter() {
      this.searchValue = '';
      this.params.filterChangedCallback();
      this.params.api.hidePopupMenu();
    },
  },
};
</script>
<style lang="scss">
.ag-menu {
  border: none !important;
  border-radius: 10px !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #481173;
  background-color: #481173;
}
.dropdown-toggle::after {
  visibility: hidden;
}
.table-filter-modal {
  z-index: 1000;
  padding: 12px 16px;
  background-color: white;
  width: 272px;
  &__wrap_sort {
    margin-bottom: 1rem;

    & a {
      display: inline-block;

      &:first-child {
        margin-bottom: 0.5rem;
      }
    }
  }
}
.input-icon {
  position: absolute;
  top: 50%;
  left: 12px;
  font-size: 16px;
  transform: translateY(-50%);
  color: #908f91;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.input-search.form-control {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: 42px;
  width: 240px;
  border-radius: 10px;
  color: #908f90;
  padding: 0 12px 0 37px;
}

.checkboxes-content {
  height: 200px;
  border: 1px solid #f1f1f1;
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  overflow-y: auto;
}
.modal-checkbox {
  margin-top: 5px;
  color: #908f90;
  display: flex;
  align-items: center;
  .custom-control-label {
    padding: 5px;
  }
}
.modal-btn-cancel {
  border-radius: 10px;
  color: #908f90;
  font-size: 14px;
  background: white;
  height: 30px;
  width: 80px;
  border: 1px solid #908f90;
}
.modal-btn-submit {
  border-radius: 10px;
  color: white;
  background: #481173;
  height: 30px;
  font-size: 14px;
  width: 100px;
  border: none;
}
</style>
