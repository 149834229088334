<template>
  <div class="report-6">
    <div class="productsAdvertising-filter wrap_page_filter">
      <report-header />
    </div>
    <div v-if="isHasSubscribe && hasProductsSuitableSubscription">
      <b-skeleton-table
        v-if="loading"
        :rows="5"
        :columns="4"
        :table-props="{ bordered: true, striped: true }"
      />
      <Table v-else-if="products" />
      <NoData v-else />
    </div>
    <PartEmpty v-else :notSuitableTariff="!hasProductsSuitableSubscription" />
  </div>
</template>

<script>
import ReportHeader from '@/views/reports/report-6/components/header.vue';
import Table from '@/views/reports/report-6/components/Table.vue';
import { mapActions, mapGetters } from 'vuex';
import PartEmpty from '@/components/empty/PartEmpty.vue';
import NoData from '@/components/no-data/NoData.vue';

export default {
  name: 'index',
  components: { NoData, PartEmpty, Table, ReportHeader },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['currentOrganization', 'isHasSubscribe']),
    ...mapGetters('report6', [
      'loading',
      'products',
      'hasProductsSuitableSubscription',
    ]),
  },
  mounted() {
    if (this.currentOrganization.id === null) {
      return;
    }
    this.setDefaultOptions(this.currentOrganization.id);
    this.getData();
  },
  watch: {
    'currentOrganization.id'(newId) {
      this.setOptions({ wb_account: newId });
    },
  },
  methods: {
    ...mapActions('report6', ['setDefaultOptions', 'getData']),
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';

$borderColor: rgba(0, 0, 0, 0.15);
.image-preview__wrapper {
  position: fixed;
  transform: translateY(-50%);
  z-index: 1000;
}

.col-settings {
  position: sticky;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: start;
}
</style>
